import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const AccountingReportService = () => {}

AccountingReportService.GetAccountingReport = async(queryString)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/report/accounting?${queryString}`, {})
}

AccountingReportService.GetAccountingIncDetail = async(otherNo)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/report/accounting/admin/incentive/${otherNo}`, {})
}

export default AccountingReportService