<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2><b>ระบบเบิกค่าใช้จ่าย (ฝ่ายบัญชี)</b></h2>
          </b-col>
          <b-col md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <v-select
                style="width: 30%"
                id="brand"
                v-model="employeeSelected"
                label="fullname"
                :options="employeeOption"
                class="ml-1"
                :clearable="false"
              />
              <v-select
                style="width: 20%"
                id="brand"
                v-model="monthSelected"
                label="title"
                :options="monthOption"
                class="ml-1"
                :clearable="false"
                @input="onChangeMonth"
              />
              <v-select
                style="width: 12%"
                id="brand"
                v-model="yearSelected"
                label="title"
                :options="yearOption"
                class="ml-1"
                :clearable="false"
                @input="onChangeYear"
              />
            </b-form>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="incentiveOtList"
              :fields="incentiveOtField"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

              <template #cell(processName)="row">
                <center>
                  <b-badge
                    pill
                    v-if="row.item.processNo >= 4"
                    variant="success"
                    >{{ row.item.processName }}</b-badge
                  >
                  <b-badge pill v-else variant="info">{{
                    row.item.processName
                  }}</b-badge>
                </center>
              </template>

              <template
                #cell(action)="row"
                v-if="accountAdminPermission == 'edit'"
              >
                <center>
                  <b-button
                    variant="outline-secondary"
                    class="mr-1"
                    size="sm"
                    pill
                    @click="print(row.item.otherNo)"
                  >
                  
                    <feather-icon
                      icon="FileTextIcon"
                      class="cursor-pointer text-secondary"
                    />
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    pill
                    @click="
                      $router.push(`/accounting/admin/approve/${row.item.id}`)
                    "
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="cursor-pointer text-white"
                    />
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BSpinner,
  BForm
} from "bootstrap-vue";
import vSelect from "vue-select";
import Field from "./field";
import moment from "moment";

//SERVICE
import UserService from "@/services/user";
import IncentiveOTService from "@/services/incentiveOT";
import AccountingReportService from "@/services/report/accounting";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import THBText from "thai-baht-text";
import th_saraban_normal from "./fonts/th_saraban_normal.json";
import th_saraban_bold from "./fonts/th_saraban_bold.json";
import commaNumber from 'comma-number'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BSpinner,
    BForm,
    vSelect,
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      monthSelected: { title: "ทุกเดือน", value: "0" },
      yearSelected: {},
      monthOption: [
        { title: "ทุกเดือน", value: "0" },
        { title: "มกราคม", value: "01" },
        { title: "กุมภาพันธ์", value: "02" },
        { title: "มีนาคม", value: "03" },
        { title: "เมษายน", value: "04" },
        { title: "พฤษภาคม", value: "05" },
        { title: "มิถุนายน", value: "06" },
        { title: "กรกฎาคม", value: "07" },
        { title: "สิงหาคม", value: "08" },
        { title: "กันยายน", value: "09" },
        { title: "ตุลาคม", value: "10" },
        { title: "พฤศจิกายน", value: "11" },
        { title: "ธันวาคม", value: "12" },
      ],
      incentiveOtField: Field,
      incentiveOtList: [],
      employeeSelected: {},
      employeeOption: [],
      yearOption: [],
      isLoading: false,
      monthQuery: "",
      yearQuery: "",
    };
  },

  created() {
    //SET DATE TO DROPDOWN ON LOAD
    let currentYear = new Date().getFullYear();
    let currentMonth =
      moment().format("M").length > 1
        ? moment().format("M")
        : `0${moment().format("M")}`;

    const year = currentYear;
    const thaiYear = currentYear + 543;
    this.yearOption.push({ title: thaiYear, value: year });
    this.yearOption.push({ title: thaiYear - 1, value: year - 1 });
    this.yearOption.push({ title: thaiYear - 2, value: year - 2 });
    this.yearSelected = { title: thaiYear, value: year };
    this.monthQuery = `${currentMonth}`;
    this.yearQuery = `${year}`;

    const monthSelected = this.monthOption.find((f) => f.value == currentMonth);
    this.monthSelected = monthSelected;
  },

  watch: {
    employeeSelected() {
      this.getIncentiveOtList();
    },
  },
  computed: {
    accountAdminPermission() {
      const permission =  this.$store.getters["user/getPermissionPage"]("AC002");
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    visibleFields() {
      return this.incentiveOtField.filter((field) => {
        if (this.$store.getters["user/getPermissionPage"]("AC002") !== "edit") {
          return field.key !== "action";
        }
        return field;
      });
    },
  },
  mounted() {
    this.getUserList();
    this.getIncentiveOtList();
    
  },
  methods: {
    async getUserList() {
      try {
        const result = await UserService.GetUserList({}, "type=employee");
        this.employeeOption = [
          { userId: 0, fullname: "พนักงานทั้งหมด" },
          ...result.data.data,
        ];
        this.employeeSelected = { userId: 0, fullname: "พนักงานทั้งหมด" };
        //this.employeeSelected = result.data.data[0];
      } catch (error) {
        alert("Load employee data fail!");
        console.log(error);
      }
    },
    async getIncentiveOtList() {
      try {
        this.isLoading = true;
        const qs = `${this.yearQuery}-${this.monthQuery}`;
        const result = await IncentiveOTService.GetIncentiveOTAdminListByEmp(
          {},
          this.employeeSelected.userId,
          qs
        );
        this.incentiveOtList = result.data;
        this.totalRows = this.incentiveOtList.length;
        this.isLoading = false;
      } catch (error) {
        alert("Load incentive ot data fail!");
        console.log(error);
      }
    },

    onChangeMonth(m) {
      this.monthQuery = m.value;
      this.getIncentiveOtList();
    },
    onChangeYear(y) {
      this.yearQuery = y.value;
      this.getIncentiveOtList();
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    removeHtmlTags(inputString) {  
      let newText= inputString.replace(/<\/?[^>]+(>|$)/g, "");
      return newText.trim().replace(/\n/g, "");
    },

    async print(otherNo) {
      const result = await AccountingReportService.GetAccountingIncDetail(otherNo)
      const ictOt = result.data.data.ictOt 
      const resultOtDetail  = result.data.data.resultOtDetail 
      const resultClearBill = result.data.data.resultClearBill 
      const usedCost = result.data.data.usedCost 
      

      let doc = new jsPDF("l", "mm", "a4");
     
      doc.addFileToVFS("THSarabun Bold-bold.ttf", th_saraban_bold.data);
      doc.addFont("THSarabun Bold-bold.ttf", "THSarabun", "bold");
      doc.addFileToVFS("THSarabun-normal.ttf", th_saraban_normal.data);
      doc.addFont("THSarabun-normal.ttf", "THSarabun", "normal");

      //HEADER
      doc.setFont("THSarabun", "bold");
      doc.text(
        "ใบเบิกค่าเดินทางและค่าเบี้ยเลี้ยงต่างจังหวัด",
        145,
        15,
        null,
        null,
        "center"
      );

      doc.setFont("THSarabun", "normal");
      doc.setFontSize(11);
      doc.text(
        `เลขที่_________${ictOt.otherNo}_________`,
        290,
        25,
        null,
        null,
        "right"
      );
      doc.text(
        `วันที่_________${this.convertDate(ictOt.otherDate,"date")}_________`,
        290,
        33,
        null,
        null,
        "right"
      );

      //CONTENT
      doc.setFont("THSarabun", "normal");

      //TABLE 1 : DESCRIPTION  =============================
      autoTable(doc, {
        theme: "grid",
        bodyStyles: { lineColor: [0, 0, 0], textColor: "#000", fontSize: 11 },
        margin: { top: 40, right: 100, left:10 },
        columnStyles: {
          0: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 35,
          },
          1: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
          2: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
          3: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
          4: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
          5: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
        },
        body: [
          [
            { content: "ชื่อผู้เบิก", styles: { halign: "left" } },
            { content: ictOt.fullname, colSpan: 5, styles: { halign: "left" } },
          ],
          [
            { content: "รายละเอียด", styles: { halign: "left" } },
            {
              content: ictOt.otherDescription,
              colSpan: 5,
              styles: { halign: "left" },
            },
          ],
          [
            { content: "วันที่เดินทางไป", styles: { halign: "left" } },
            { content: this.convertDate(ictOt.otherRealGoDate, "date"), styles: { halign: "left" } },
            { content: "วันที่เดินทางกลับ", styles: { halign: "left" } },
            { content: this.convertDate(ictOt.otherRealBackDate, "date"), styles: { halign: "left" } },
            { content: "รวมวันเดินทาง", styles: { halign: "left" } },
            { content: ictOt.otherTotalDate, styles: { halign: "center" } },
          ],
        ],
        styles : {
          cellPadding : 1,
        }
      });

      //TABLE 2 : INCENTIVE DATA  ==================================
      autoTable(doc, {
        theme: "grid",
        bodyStyles: { lineColor: [0, 0, 0], textColor: "#000", fontSize: 11 },
        margin: { top: 50, right: 80 , left:10, bottom:20},
        columnStyles: {
          0: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 35,
          },
          1: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
          2: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
          3: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
          4: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
        },
        body: [
          [
            { content: "ลำดับ", styles: { halign: "center" } },
            { content: "รายละเอียด", styles: { halign: "center" } },
            { content: "จำนวนเงิน", styles: { halign: "center" } },
            { content: "จำนวนที่ใช้จริง", styles: { halign: "center" } },
            { content: "จำนวนเงินรวม", styles: { halign: "center" } },
          ],
          [
            { content: "1", styles: { halign: "center" } },
            {
              content: `ค่าที่พักต่างจังหวัดจำนวน _${ictOt.otherRentalDay}_ คืน ใช้จริง _${ictOt.otherRentalDay}_ คืน`,
              styles: { halign: "left" },
            },
            { content: commaNumber(ictOt.otherRentalAmount.toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber(usedCost.feeHotel.toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber((ictOt.otherRentalAmount-usedCost.feeHotel).toFixed(2)), styles: { halign: "right" } },
          ],
          [
            { content: "2", styles: { halign: "center" } },
            {
              content: `ค่าเบี้ยเลี้ยงต่างจังหวัดจำนวน _${ictOt.otherExpensesPerDay}_ วัน`,
              styles: { halign: "left" },
            },
            { content: commaNumber(ictOt.otherExpensesPerDayAmount.toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber(usedCost.allowance.toFixed(2)), styles: { halign: "right" } , styles: { halign: "right" } },
            { content: commaNumber((ictOt.otherExpensesPerDayAmount-usedCost.allowance).toFixed(2)), styles: { halign: "right" }, styles: { halign: "right" } },
          ],
          [
            { content: "3", styles: { halign: "center" } },
            { content: "ค่าน้ำมันรถ หรือค่าแก๊ส", styles: { halign: "left" } },
            { content: commaNumber(ictOt.otherGasOil.toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber(usedCost.feeOilGas.toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber((ictOt.otherGasOil-usedCost.feeOilGas).toFixed(2)), styles: { halign: "right" } },
          ],
          [
            { content: "4", styles: { halign: "center" } },
            { content: "ค่าทางด่วน", styles: { halign: "left" } },
            { content: commaNumber(ictOt.expressWay), styles: { halign: "right" } },
            { content: commaNumber((usedCost.expressPosition).toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber((ictOt.expressWay-(usedCost.expressPosition).toFixed(2))), styles: { halign: "right" } },
          ],

          [
            { content: "5", styles: { halign: "center" } },
            { content: "ค่าใช้จ่ายอื่นๆ", styles: { halign: "left" } },
            { content: commaNumber(ictOt.otherPayTotal), styles: { halign: "right" } },
            { content: commaNumber((usedCost.freeOther).toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber((ictOt.otherPayTotal-(usedCost.freeOther).toFixed(2))), styles: { halign: "right" } },
          ],
          [
            {
              content: `รวมเป็นเงิน ${(this.sumIncentiveOt(ictOt)-this.sumIncentiveUsed(usedCost)) < 0 ? '(เบิกเพิ่ม)' : '(เหลือคืน)'}`,
              styles: { halign: "center" },
            },
            {
              content: `( ${THBText(Math.abs(this.sumIncentiveOt(ictOt)-this.sumIncentiveUsed(usedCost)))} )`,
              // content: `( ${THBText(this.sumIncentiveOt(ictOt)-this.sumIncentiveUsed(usedCost))} )`,
              styles: { halign: "center" },
            },
            {
              content: commaNumber(this.sumIncentiveOt(ictOt).toFixed(2)),
              styles: { halign: "right" },
            },
            { content: commaNumber((this.sumIncentiveUsed(usedCost)).toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber((this.sumIncentiveOt(ictOt)-this.sumIncentiveUsed(usedCost)).toFixed(2)), styles: { halign: "right" } },
          ],
        ],
        styles : {
          cellPadding : 1,
        }
      });

      //TABLE 3 : INCENTIVE DETAIL  =====================================
      // doc.addPage()
      doc.setFont("THSarabun", "bold");
 
      doc.text("รายละเอียดการเดินทาง", 10, doc.autoTable.previous.finalY + 5);

      //HEAD
      let incDetailList = [
          [
            { content: "#", styles: { halign: "center" } },
            { content: "วันที่", styles: { halign: "center" } },
            { content: "ใบงานเลขที่", styles: { halign: "center" } },
            { content: "รหัสสถานี", styles: { halign: "center" } },
            { content: "เริ่ม - ถึง", styles: { halign: "center" } },
            { content: "ไมล์เริ่ม", styles: { halign: "center" } },
            { content: "ไมล์ถึง", styles: { halign: "center" } },
            { content: "ไมล์รวม", styles: { halign: "center" } },
            { content: "ค่าที่พัก", styles: { halign: "center" } },
            { content: "เบี้ยเลี้ยง", styles: { halign: "center" } },
            { content: "ค่าน้ำมัน", styles: { halign: "center" } },
            { content: "ค่าทางด่วน", styles: { halign: "center" } },            
            { content: "ค่าอื่นๆ", styles: { halign: "center" } },
            { content: "Incentive", styles: { halign: "center" } },            
            
          ],
        ]
        //BODY
        const countIctDetail = resultOtDetail.length
        for (let index = 0; index < countIctDetail; index++) {
          const ictDetail = resultOtDetail[index];
          incDetailList.push([
            { content: index+1, styles: { halign: "center" } },
            { content: this.convertDate(ictDetail.date, "date"), styles: { halign: "center" } },
            { content:  ictDetail.ticketNo, styles: { halign: "center" } },           
            { content: ictDetail.branchCode, styles: { halign: "center" } },
            { content: `${this.cutoffText(ictDetail.locationForm, 45)} - ${this.cutoffText(ictDetail.locationTo,45)}`, styles: { halign: "left" } },
            { content: ictDetail.distanceFrom, styles: { halign: "center" } },
            { content: ictDetail.distanceTo, styles: { halign: "center" } },
            { content: ictDetail.ditstanceResult, styles: { halign: "right" } },
            { content: commaNumber(ictDetail.feeHotel.toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber(ictDetail.allowance.toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber(ictDetail.feeOilGas.toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber(ictDetail.expressPosition.toFixed(2)), styles: { halign: "right" } },           
            { content: commaNumber(ictDetail.freeOther.toFixed(2)), styles: { halign: "right" } },
            { content:commaNumber(ictDetail.incentive.toFixed(2)), styles: { halign: "right" } },
            
            
          ])

          incDetailList.push(
          [
            { content: `รายละเอียด : ${this.removeHtmlTags(ictDetail.ticketDescription) || '-'}`, styles: { halign: "left" }, colSpan:14 },   
          ]
          )

          
        }

        //FOOTER
        let footer = [
            { content: "รวมทั้งหมด (กม.) : ", styles: { halign: "right" }, colSpan:5 }, 
            { content: usedCost.mile, styles: { halign: "center" } ,colSpan:3 },  
            { content: commaNumber(usedCost.feeHotel.toFixed(2)), styles: { halign: "right" } },  
            { content: commaNumber(usedCost.allowance.toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber(usedCost.feeOilGas.toFixed(2)), styles: { halign: "right" } },
            { content: commaNumber(usedCost.expressPosition.toFixed(2)), styles: { halign: "right" } },   
            { content: commaNumber(usedCost.freeOther.toFixed(2)), styles: { halign: "right" } },                
            { content: commaNumber(usedCost.incentive.toFixed(2)), styles: { halign: "right" } },   
          ]
        incDetailList.push(footer)

      autoTable(doc, {
        theme: "grid",
        bodyStyles: { lineColor: [0, 0, 0], textColor: "#000", fontSize: 11 },
        margin: { top: 8, right: 10,left:10 , bottom:8},
        columnStyles: {
          0: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 8,
          },
          1: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 16,
            
          },
          2: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 16,
           
          },
          3: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 15.5,
          
          },
          4: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 105,
          
          },
          5: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 13,
          },
          6: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 13,
          },
          7: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 13,
          },
          8: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 13,
          },
          9: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 13,
          },
          10: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 13,
          },
          11: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 13,
          },
          12: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 13,
          },
          13: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 13,
          },
        },
        body: incDetailList,
        styles : {
          cellPadding : 1,
        }
      });



      //TABLE 4 : CLEARBILL ============================================
      //doc.addPage()
      doc.setFont("THSarabun", "bold");
      doc.text("รายละเอียดใบเสร็จ", 10, doc.autoTable.previous.finalY + 5);

      let clearBillList=[
          [
            { content: "ลำดับ", styles: { halign: "center" } },
            { content: "วันที่", styles: { halign: "center" } },
            { content: "ประเภท", styles: { halign: "center" } },
            { content: "รายละเอียด", styles: { halign: "center" } },
            { content: "บิลเลขที่", styles: { halign: "center" } },
            { content: "จำนวนเงิน", styles: { halign: "center" } },
           
          ],
        ]

        let countClearBill = resultClearBill.length 
        let sumClearBill= 0
        for (let index = 0; index < countClearBill; index++) {
          const clearBill = resultClearBill[index];
          sumClearBill+=clearBill.amount
          clearBillList.push(
            [
            { content: index+1, styles: { halign: "center" } },
            { content: this.convertDate(clearBill.date, "date"), styles: { halign: "center" } },
            { content: clearBill.costName, styles: { halign: "center" } },
            { content: clearBill.description, styles: { halign: "left" } },
            { content: clearBill.billNo, styles: { halign: "center" } },
            { content: commaNumber(clearBill.amount.toFixed(2)), styles: { halign: "right" } },           
          ],
          )
        }


        clearBillList.push(
          [
            { content: "รวม :", styles: { halign: "right" }, colSpan:5 },   
            { content: commaNumber(sumClearBill.toFixed(2)), styles: { halign: "right" } },   
          ],
        )

        
      autoTable(doc, {
        theme: "grid",
        bodyStyles: { lineColor: [0, 0, 0], textColor: "#000", fontSize: 11 },
        margin: { top: 25, right: 50, left:10 },
        columnStyles: {
          0: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 15,
          },
          1: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 20,
          },
          2: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 30,
          },
          3: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
          4: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 30,
          },
          5: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 20,
          },
        
        },
        body: clearBillList,
        styles : {
          cellPadding : 1,
        }
      });


      //CHECK BEFORE GEN INCENTIVE PAGE
      const filtered = resultOtDetail.filter(f=>f.incentive>0)      
      if(filtered.length==0){
        //END
        doc.save(`${ictOt.otherNo}.pdf`);
        return
      }


      //TABLE 5 : INCENTIVE ============================    
      doc.addPage("a4", "portrait")
      doc.setFont("THSarabun", "bold");  
      doc.setFontSize(16);
      doc.text(
        "INCENTIVE REPORT",
        100,
        15,
        null,
        null,
        "center"
      );     
      doc.text(
        "ใบเคลียร์เบิกเงินเดินทางและค่าเบี้ยเลี้ยงต่างจังหวัด",
        100,
        22,
        null,
        null,
        "center"
      );    
      
      doc.setFont("THSarabun", "normal");
      doc.setFontSize(11);
      doc.text(
        `เลขที่_________${ictOt.otherNo}_________`,
        200,
        35,
        null,
        null,
        "right"
      );
      doc.text(
        `วันที่_________${this.convertDate(ictOt.otherDate,"date")}_________`,
        200,
        40,
        null,
        null,
        "right"
      );
      

      //TABLE INCENTIVE : DESCRIPTION  =============================
      autoTable(doc, {
        theme: "grid",
        bodyStyles: { lineColor: [0, 0, 0], textColor: "#000", fontSize: 11 },
        margin: { top: 50, right: 10, left:10 },
        columnStyles: {
          0: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 35,
          },
          1: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
          2: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
          3: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
          4: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
          5: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
          },
        },
        body: [
          [
            { content: "ชื่อผู้เบิก", styles: { halign: "left" } },
            { content: ictOt.fullname, colSpan: 5, styles: { halign: "left" } },
          ],
          [
            { content: "รายละเอียด", styles: { halign: "left" } },
            {
              content: ictOt.otherDescription,
              colSpan: 5,
              styles: { halign: "left" },
            },
          ],
          [
            { content: "วันที่เดินทางไป", styles: { halign: "left" } },
            { content: this.convertDate(ictOt.otherRealGoDate, "date"), styles: { halign: "left" } },
            { content: "วันที่เดินทางกลับ", styles: { halign: "left" } },
            { content: this.convertDate(ictOt.otherRealBackDate, "date"), styles: { halign: "left" } },
            { content: "รวมวันเดินทาง", styles: { halign: "left" } },
            { content: ictOt.otherTotalDate, styles: { halign: "center" } },
          ],
        ],
        styles : {
          cellPadding : 1,
        }
      });


      let incentiveList=[
          [
            { content: "#", styles: { halign: "center" } },
            { content: "วันที่", styles: { halign: "center" } },
            { content: "ใบงานเลขที่", styles: { halign: "center" } },
            { content: "รหัสสถานี", styles: { halign: "center" } },
            { content: "Incentive", styles: { halign: "center" } },
            { content: "รายละเอียดของงาน", styles: { halign: "center" } },
           
          ],
        ]

        let countIncentive = resultOtDetail.length 
        let sumIncentive= 0 
        let orderNum = 1
        for (let index = 0; index < countIncentive; index++) {
          const inc = resultOtDetail[index];
            if(inc.incentive>0){
              sumIncentive+=inc.incentive
              incentiveList.push(
              [
              { content: orderNum, styles: { halign: "center" } },
              { content: this.convertDate(inc.date, "date"), styles: { halign: "center" } },
              { content: inc.ticketNo, styles: { halign: "center" } },
              { content: inc.branchCode, styles: { halign: "center" } },
              { content: commaNumber(inc.incentive.toFixed(2)), styles: { halign: "right" } },
              { content: `${this.removeHtmlTags(inc.ticketDescription) || '-'}`, styles: { halign: "left" } },           
              ],
              )

              orderNum+=1
            }
          
           

          
          
        }


        incentiveList.push(
          [
            { content: "รวมเป็นเงิน (บาท) :", styles: { halign: "right" }, colSpan:4 },   
            { content: commaNumber(sumIncentive.toFixed(2)), styles: { halign: "right" } },   
            // { content: commaNumber(sumIncentive.toFixed(2)), styles: { halign: "right" } },   
          ],
        )        

      autoTable(doc, {
        theme: "grid",
        bodyStyles: { lineColor: [0, 0, 0], textColor: "#000", fontSize: 11 },
        margin: { top: 25, right: 10, left:10 },
        columnStyles: {
          0: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 8,
          },
          1: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 15,
          },
          2: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 20,
          },
          3: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 15,
          },
          4: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            cellWidth: 15,
          },
          5: {
            font: "THSarabun",
            fontStyle: "normal",
            fillColor: [255, 255, 255],
            
          },
        
        },
        body: incentiveList,
        styles : {
          cellPadding : 1,
        }
      });

      //SAVE
    
      doc.save(`${ictOt.otherNo}.pdf`);
    },
    sumIncentiveOt(incentiveOt){
      return incentiveOt.otherRentalAmount + incentiveOt.otherGasOil + incentiveOt.otherPayTotal + incentiveOt.otherExpensesPerDayAmount
    },
    sumIncentiveUsed(incentiveUsed){
      return incentiveUsed.feeHotel + incentiveUsed.feeOilGas + incentiveUsed.allowance + (incentiveUsed.expressPosition + incentiveUsed.freeOther)
    },
    cutoffText(text, length){     
      const string = text
      const trimmedString = string.length > length ?
        string.substring(0, length - 3) + "..." :string

      return trimmedString
    },
    convertDate(date, type = "") {
      if (type == "date") {
        return moment(date).format("DD-MM-yyyy");
      }

      if(type=="orginal"){
        return moment(date).subtract(7, 'h').format("yyyy-MM-DD HH:mm:ss");
      }
      return moment(date).format("yyyy-MM-DD HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
